.css-1s2u09g-control {
	height: 40px;
	border-radius: 0;
	border: 0;
}
.hamburger--top {
	height: 4px;
	width: 30px;
	background-color: #333333;
	z-index: 40;
  }
  .hamburger--middle {
	height: 4px;
	width: 30px;
	background-color: #333333;
  }
  .hamburger--bottom {
	height: 2px;
	width: 22px;
	background-color: #333333;
  }
  
  .nav {
	-webkit-backface-visibility: hidden;
  }
  
  .stage {
	--cell-width: 200px;
	--cell-height: calc(var(--cell-width) * 1.2);
	--cell-spacing: 4px;
  
	padding-top: calc(var(--cell-width) * 0.25);
	padding-bottom: calc(var(--cell-width) * 0.25);
  }
  
  .row {
	display: flex;
	justify-content: center;
	margin-top: calc(var(--cell-width) * -0.27 + var(--cell-spacing));
	margin-bottom: calc(var(--cell-width) * -0.27 + var(--cell-spacing));
  }
  
  .cell {
	clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
	background-color: steelblue;
	width: var(--cell-width);
	height: var(--cell-height);
	margin-left: var(--cell-spacing);
	margin-right: var(--cell-spacing);
  }
  