.slide {
	position: absolute;
	opacity: 0;
	transition: opacity ease-in-out 1.2s;
}
.slide img {
	object-fit: cover;
}
.active-anim {
	opacity: 1;
}

.btn-slide {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background: #f1f1f1;
	border: 1px solid rgba(34, 34, 34, 0.287);
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.btn-slide img {
	width: 25px;
	height: 25px;
	pointer-events: none;
}
.prev {
	left: 20px;
	transform: translateY(-60%);
}
.next {
	right: 20px;
	transform: translateY(-60%);
}

.container-dots {
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
}
.dot {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 3px solid #f1f1f1;
	margin: 0 5px;
	background: #f1f1f1;
	cursor: pointer;
}
.dot.active {
	background: rgb(32, 32, 32);
}
