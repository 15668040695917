h2 {
	display: flex;
	flex-direction: row;
}
.borderLine:before,
.borderLine:after {
	content: '';
	border-bottom: 1px solid;
	margin: auto;
	width: 20%;
}
.borderLine:before {
	margin-right: 10px;
}
.borderLine:after {
	margin-left: 10px;
}
