.centered {
	position: absolute;
	top: 50%;
	font-family: 'Inconsolata' !important;
	color: white !important;
	transform: translate(-50%, -50%);
}
h3 {
	font-size: 0.8rem;
	font-weight: 700;
}
.react-datepicker__month-container {
	z-index: 10;
}
.react-datepicker__day--highlighted {
	background-color: #b4b4b475;
}
h2:before,
h2:after {
	content: '';
	border-bottom: 0px solid;
	margin: auto;
	width: 20%;
}
h2:before {
	margin-right: 10px;
}
h2:after {
	margin-left: 10px;
}

.higher-z-index {
	position: relative;
	/* Make sure the element is positioned */
	z-index: 2;
	/* Higher than the div's z-index */
}